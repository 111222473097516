<template>
  <div>
    <section id="page-title">
      <h2>Privacy Policy</h2>
    </section>
    <section id="privacy-policy" class="content-body">
      <p>Last updated: July 10, 2020</p>
      <h4>Summary</h4>
      <p>
        We respect the EU’s <strong>General Data Protection Regulations (GDPR)</strong>;
        this policy explains how we collect and treat any information you give us through this website. This policy only
        covers this website; it does not cover how CAMRA as a national organisation handles your personal information.
        This can be found in the
        <a href="http://www.camra.org.uk/privacy-policy" target="_blank">CAMRA Privacy Policy</a>.
      </p>
      <h4>Our policy covers:</h4>
      <ol>
        <li>Why we value your privacy</li>
        <li>How we collect information</li>
        <li>What information we collect</li>
        <li>Where we store your information</li>
        <li>What we use your information for</li>
        <li>Who has access to information about you</li>
        <li>The steps we take to keep your information private</li>
        <li>How to complain</li>
        <li>Changes to the policy</li>
      </ol>
      <h4>Why we value your privacy</h4>
      <p>
        We value your privacy as much as we do our own, so we’re committed to keeping your personal information safe. We
        only ask for the bare minimum necessary, will never use your personal information for any reason other than why
        you gave it, and will never give anyone access to it unless we're forced to by law.
      </p>
      <h4>How we collect information</h4>
      <p>
        We use our own install of
        <safe-link to="https://matomo.org/what-is-matomo/" target="_blank">Matomo Analytics</safe-link>.
        The data collected by this is stored on our own server and is not available to any third parties, including Matomo.
        You can prevent this data from being collected by setting the Do Not Track option in your browser, or by denying
        consent using the controls at the bottom of this page.
      </p>
      <p>
        Pages with maps on them retrieve their data from
        <safe-link to="https://wiki.osmfoundation.org/wiki/Privacy_Policy" target="_blank">OpenStreetMap</safe-link>. We use this
        service rather than the more common Google or Bing maps as they collect less personal data; generally, their data
        collection policies are similar to ours. You can prevent them from setting cookies by disabling third-party
        cookies in your browser.
      </p>
      <p>
        Other essential parts of the page, such as fonts or JavaScript libraries are hosted on our own site rather than
        third-party content delivery networks. This is to minimise leakage of personal information.
      </p>
      <h4>What information we collect</h4>
      <p>
        We no longer use any analytics packages; unless you log in as a site editor, no information is collected from
        you.
      </p>
      <p>
        If you consented to our use of Matomo analytics previously, you may still have these cookies in your browser.
        You can delete these without affecting the way the site operates.
      </p>
      <table class="table cookies">
        <thead>
          <tr>
            <th>Name</th>
            <th>Duration</th>
            <th>Purpose</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <span class="cookie-name">_pk_id</span>
            </td>
            <td>
              13 months
            </td>
            <td>
              Unique ID, randomly generated. Allows us to detect return visits from the same browser.
            </td>
          </tr>
          <tr>
            <td>
              <span class="cookie-name">_pk_ref</span>
            </td>
            <td>
              6 months
            </td>
            <td>
              Referrer; the site that you came to our site from (for example: if you came from a Google search)
            </td>
          </tr>
          <tr>
            <td>
              <span class="cookie-name">_pk_ses</span>
            </td>
            <td>
              30 minutes
            </td>
            <td>
              Temporary storage
            </td>
          </tr>
          <tr>
            <td>
              <span class="cookie-name">_pk_cvar</span>
            </td>
            <td>
              30 minutes
            </td>
            <td>
              Temporary storage
            </td>
          </tr>
          <tr>
            <td>
              <span class="cookie-name">_pk_hsr</span>
            </td>
            <td>
              30 minutes
            </td>
            <td>
              Temporary storage
            </td>
          </tr>
          <tr>
            <td>
              <span class="cookie-name">_pk_testcookie</span>
            </td>
            <td>
              1 second
            </td>
            <td>
              Used to test if your browser supports cookies; immediately deleted.
            </td>
          </tr>
        </tbody>
      </table>
      <h4>Where we store your information</h4>
      <p>
        All information is stored on our web server, which is hosted in the United Kingdom.
      </p>
      <h4>What we use your information for</h4>
      <p>
        We do <em>not</em> use your information for sending marketing emails, or for allowing others to do so.
      </p>
      <h4>Who has access to information about you</h4>
      <p>
        The site administrator has access to all stored information. Access is not granted to anybody else, except
        where we are required to do so by law.
      </p>
      <h4>The steps we take to keep your information private</h4>
      <p>
        The servers that run our web site can only be accessed using SSH with an encrypted key;
        login with username and password is blocked. We run an intrusion detection system that detects repeated
        incorrect login attempts and automatically blocks access from that address. Our software is regularly updated.
      </p>
      <p>
        The hosting platform we use for the servers is protected with strong passwords that are stored in a password
        manager, and two-factor authentication that requires a time-sensitive code to be entered.
      </p>
      <h4>How to complain</h4>
      <p>
        If you have any complaints or queries about the way we process your personal information, please
        <safe-link :to="contactPath">contact us</safe-link>.
      </p>
      <h4>Changes to the policy</h4>
      <p>
        If we change the contents of this policy, the changes will become effective the moment we publish them. However,
        we will not change the policy to make wider use of personal information without advance notification.
      </p>
      <h4>Acknowledgement</h4>
      <p>
        This policy is based on the
        <safe-link to="https://stuffandnonsense.co.uk/projects/protection-racket" target="_blank">Protection Racket</safe-link>
        template, provided by Stuff &amp; Nonsense.
      </p>
    </section>
  </div>
</template>

<script>
  import SafeLink from '@/components/helpers/safe-link'

  export default {
    name: 'privacy-policy',

    components: {
      SafeLink,
    },

    computed: {
      contactPath () {
        // TODO: App.vue should get the paths from settings and put them in store (rather than site-navbar, which should just read the store)
        return '/contact'
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/bulma-variables.scss';

  h4 {
    margin-top: 0.5rem;
  }

  p {
    margin-bottom: 0.5rem;
  }

  strong {
    color: #363636;
  }

  .cookies {
    .cookie-name {
      font-family: $family-monospace;
      font-size: 0.8rem;
    }
  }
</style>
