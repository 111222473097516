import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.config.productionTip = false

// Buefy: CSS framework
import Buefy from 'buefy'
import '@mdi/font/css/materialdesignicons.min.css'
Vue.use(Buefy, {
  defaultLocale: 'en-GB',
  defaultToastPosition: 'is-top-right',
  defaultToastDuration: 2500,
  defaultTooltipType: 'is-dark',
  defaultUseHtml5Validation: false,
  defaultTrapFocus: true,
})

// Vuelidate: validations
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// Vue-snip: CSS line-clamp is a long way from being universally available
import VueSnip from 'vue-snip'
Vue.use(VueSnip, {
  ellipsis: '…',
  // Do not use 'css' as Cypress's .invoke('text') returns the full text rather than what the user sees.
  snipMethod: 'js', // FIXME: hopefully this can be changed back to 'css' eventually
})

// VCalendar: calendars and datepickers
import VCalendar from 'v-calendar'
Vue.use(VCalendar, {
  locale: 'en-GB',
  timezone: 'Europe/London',
  firstDayOfWeek: 2,
  popover: { placement: 'right' },
})

// vue-cookies: why does Vue expect us to parse document.cookies ourselves?
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('1y', '', '', true) // default to 1 year expiry, https-only

// eslint-disable-next-line vue/require-name-property
new Vue({
  router,
  store,

  render: (h) => h(App),
}).$mount('#app')

// smartquotes: auto-replace "straight" quotes with “typographically-correct” ones
const smartquotes = require('smartquotes')

smartquotes.replacements.push([/\s*\.{2,}\s*/g, '… '])
smartquotes().listen()

// Support animations
import 'animate.css'
