<template>
  <div id="app">
    <div class="top">
      <site-header />
      <site-navbar />
      <div id="page-content" class="container">
        <div class="site-notice">
          <site-notice />
        </div>
        <router-view />
      </div>
    </div>
    <div class="footer container">
      <site-footer />
    </div>
  </div>
</template>

<script>
  import SiteFooter from '@/components/site-footer'
  import SiteHeader from '@/components/site-header'
  import SiteNavbar from '@/components/site-navbar'
  import SiteNotice from '@/components/site-notice'

  // eslint-disable-next-line vue/require-name-property
  export default {
    components: {
      SiteFooter,
      SiteHeader,
      SiteNavbar,
      SiteNotice,
    },
  }
</script>

<style lang="scss">
  @import '~bulma/sass/utilities/_all';
  @import '@/assets/bulma-variables.scss';
  @import '~bulma';
  @import '~buefy/src/scss/buefy';

  // muli-300 - latin_latin-ext
  @font-face {
    font-family: Muli;
    font-weight: 300;
    font-style: normal;
    src: local('Muli Light'), local('Muli-Light'), url('/fonts/muli-v12-latin_latin-ext-300.woff2') format('woff2'), url('/fonts/muli-v12-latin_latin-ext-300.woff') format('woff');
  }

  // muli-300italic - latin_latin-ext
  @font-face {
    font-family: Muli;
    font-weight: 300;
    font-style: italic;
    src: local('Muli Light Italic'), local('Muli-LightItalic'), url('/fonts/muli-v12-latin_latin-ext-300italic.woff2') format('woff2'), url('/fonts/muli-v12-latin_latin-ext-300italic.woff') format('woff');
  }

  // muli-600 - latin_latin-ext
  @font-face {
    font-family: Muli;
    font-weight: 600;
    font-style: normal;
    src: local('Muli SemiBold'), local('Muli-SemiBold'), url('/fonts/muli-v12-latin_latin-ext-600.woff2') format('woff2'), url('/fonts/muli-v12-latin_latin-ext-600.woff') format('woff');
  }

  // muli-600italic - latin_latin-ext
  @font-face {
    font-family: Muli;
    font-weight: 600;
    font-style: italic;
    src: local('Muli SemiBold Italic'), local('Muli-SemiBoldItalic'), url('/fonts/muli-v12-latin_latin-ext-600italic.woff2') format('woff2'), url('/fonts/muli-v12-latin_latin-ext-600italic.woff') format('woff');
  }

  // v-calendar now inlines its own CSS and overrides ours
  html {
    @include on-print {
      background-color: #fff;
    }

    .vc-container {
      font-family: $family-sans-serif;
    }
  }

  body {
    @include on-print {
      color: #000;
    }
  }

  // Bulma prefers .is-title etc to indicate function. This resets things to the way most people expect :)
  h1 {
    font-size: $size-1;
  }

  h2 {
    font-size: $size-2;
  }

  h3 {
    font-size: $size-3;
  }

  h4 {
    font-size: $size-4;
  }

  h5 {
    font-size: $size-5;
  }

  h6 {
    font-size: $size-6;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $weight-bold;
  }

  a {
    text-decoration: none;
    color: $link;

    @include on-print {
      text-decoration: underline dotted $grey-light;
      color: #000;
    }

    &:hover {
      text-decoration: underline;
      color: $hover-color;
    }
  }

  p {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  hr {
    background-color: $grey-lighter;
    margin: 1.5rem 1rem;
  }

  s {
    text-decoration: line-through;
  }

  // Reduce maximum container width on large displays
  @include on-widescreen {
    .container {
      max-width: 1164px;
    }
  }

  // Ensure footer displays at the bottom of the window on short pages
  #app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    .footer {
      @include on-phone {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }

      padding-bottom: 0.75rem;

      &:last-child {
        padding-bottom: 1rem;
      }
    }
  }

  // Stick consent bar to the bottom of the page
  .consent {
    position: sticky;
    bottom: 0;
  }

  // Fix modals going slightly off the right of the screen on mobile
  .modal {
    &.is-active {
      padding-right: 4px;
    }
  }

  // b-switch labels should look like other labels
  label {
    &.switch {
      // fix for switch not appearing centred vs the buttons; it appears space is
      // reserved for the box-shadow on the button's transition state.
      padding-top: 3px;

      // make switch label match other labels
      color: $body-color;
      font-weight: $weight-bold;

      &[disabled] {
        opacity: 1;
      }
    }
  }

  // Forms
  form {
    .field {
      &.invalid {
        label {
          color: $red;
        }

        input {
          border-color: $red;
        }
      }
    }
  }

  // Disabled buttons should be lighter
  .button {
    &:disabled {
      opacity: 0.3;
    }
  }

  // Bulma's `is-hidden` class uses !important, so `is-hidden is-block-mobile` remains hidden on mobile 😡
  .is-hidden-normally {
    display: none;
  }

  // Notifications
  .notification {
    &:last-child {
      margin-bottom: 1rem;
    }
  }

  #site-notices {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    background-color: $site-notices-background-color;

    .hero-body {
      padding: 1.5rem;
    }

    h4 {
      line-height: 1.3;
      color: $site-notices-color;
      font-size: 1.2rem;
      font-weight: $weight-bold;
    }

    h5 {
      color: $site-notices-color;
      font-size: 1rem;
      font-weight: $weight-bold;
    }

    p {
      color: $site-notices-color;
      font-size: 0.9rem;
    }

    @include on-phone {
      margin-top: 0.5rem;

      .hero-body {
        padding: 1rem;
      }
    }
  }

  // Lists
  ul,
  ol {
    margin-left: 2rem;

    li {
      padding-left: 0.25rem;
    }
  }

  // Forms
  .is-field-hint {
    color: $grey;
    font-size: 0.8rem;
  }

  // .tag.is-light needs to be slightly darker with this background colour
  $is-not-so-light: #d4d4d4;

  .tag {
    &:not(body) {
      &.is-light {
        background-color: $is-not-so-light;
        color: $body-color;
      }
    }
  }

  // Prevent text from spilling on to multiple lines. Use shave() for table cells.
  // FIXME: vue-clampy may render this unnecessary
  :not(td) {
    &.truncatable {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // Main content area

  #page-title {
    position: relative;
    margin-bottom: 1.25rem;
    text-align: center;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: $weight-bold;
    }
  }

  .title-buttons-left,
  .title-buttons-right {
    position: absolute;
    top: 0.5rem;
  }

  .title-buttons-left {
    left: 0;
  }

  .title-buttons-right {
    right: 0;
  }

  #page-content {
    padding: 1rem;

    @include on-phone {
      padding: 0 0.75rem 0.75rem 0.75rem;
    }

    .content-body {
      dt {
        font-weight: $weight-bold;
      }

      .nav-buttons {
        margin-top: 2rem;
        text-align: right;
      }
    }
  }

  .loading-overlay {
    .loading-icon {
      &::after {
        border-bottom-color: $loader-color;
        border-left-color: $loader-color;
      }
    }
  }

  // stylelint-disable declaration-no-important -- these need to override EVERYTHING for a11y reasons
  @media only screen and (prefers-reduced-motion: reduce) {
    * {
      transition: none !important;
      animation: none !important;
    }
  }
  // stylelint-enable declaration-no-important
</style>
